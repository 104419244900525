$themeColor: #0c8efa;
$whiteColor: #ffffff;
$blackColor: #000000;
$loginBg: #f6f7fb;
$shadowColor: #8d8d8d;
$borderColor: #cccccc;
$lightBorderColor: #dddddd;
$extraLightBorderColor: #eeeeee;
$tableHeader: #466579;
$fontColor: #616161;
$green: #6ac258;
$red: #f34330;
$blue: #337ab7;
$leftMenuBg: #07192e;
$leftMenuHover: #17355f;
$boxShadowStandered: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url('https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXjeu.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url('https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXg.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body, #oataikApp, .full-height{
  height: 100%;
}

html{
  overflow: hidden !important;
}

body {
  margin: 0;
  font-family: 'Lato' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-header{
  background-color: $themeColor;
  height: 60px;
  padding: 5px 0;

  .logo-img{
    height: 50px;
    margin-right: 10px;
    float: left;
  }

  .header-text{
    line-height: 50px;
    margin: 0;
    display: table;
    color: $whiteColor;
    white-space: nowrap;
  }
}

.login-bg{
  background-color: $loginBg;
}

.login-body{
  background-image: url('./images/background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 0 20px;
  .login-container{
    max-width: 800px;
    width: 100%;
    height: auto;
    min-height: 300px;
    margin: auto;
    margin-top: 5%;
    box-shadow: 5px 5px 10px -10px $shadowColor;
    border-radius: 20px;
    .login-left{
      background-color: $themeColor;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      color: $whiteColor;
      padding: 50px 30px;
      p{
        font-size: 14px;
        text-align: justify;
      }
    }
    .login-right{
      padding: 30px 15px;
      background-color: $whiteColor;
      height: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      h2{
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 10px;
        &:after{
          content: '.';
            display: block;
            height: 2px;
            width: 50px;
            margin: 7px auto;
            text-indent: -9999px;
            border-top: 2px solid $themeColor;
        }
        // span{
        //   border-bottom: 2px solid $themeColor;
        // }
      }
    }
    .remember-me{
      margin-top: 10px;
      .remember-me-label{
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 12px;
      }
      input[type="checkbox"]{
        vertical-align: middle;
      }
    }
    .forgot-password-btn{
      border: none;
      background: none;
      padding: 0;
      color: $themeColor;
      border-bottom: 1px solid $themeColor;
      margin-top: 10px;
      font-size: 12px;
    }
    .login-copyright{
      margin-top: 30px;
      font-size: 10px;
      text-align: center;
      color: $shadowColor;
    }
  }
}

.login-input-div{
  width: 100%;
  height: 40px;
  border: 1px solid $borderColor;
  border-radius: 4px;
  margin-top: 10px;
  input{
    border: none;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    border-radius: 4px;
    &:focus{
      outline: none;
    }
  }
  i{
    line-height: 35px;
    padding-right: 10px;
    color: $shadowColor;
  }
  .login-submit-btn{
    background-color: $themeColor;
    color: $whiteColor;
  }
  // ::placeholder{
  //   font-size: 12px;
  //   color: $themeColor;
  // }

  // ::-webkit-input-placeholder{
  //   font-size: 12px;
  //   color: $themeColor;
  // }

  // :-ms-input-placeholder{
  //   font-size: 12px;
  //   color: $themeColor;
  // }
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.pointer{
  cursor: pointer;
}

.relative{
  position: relative;
}

@media (max-width: 575px) {
  .hidden-sm{
    display: none;
  }
}

.dashboard-container{
  background-color: $loginBg;
  height: 100%;
  .dashboard-body{
    flex-wrap: nowrap;
    .dashboard-left-nav{
      height: calc(100%);
      width: 70px;
      background-color: $whiteColor;
      .left-nav-items{
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top:30px;

        li{
          text-align: center;
          img{
            max-width: 100%;
            width: 20px;
            height: 40px;
          }
          &:after{
          content: '.';
            display: block;
            height: 1px;
            width: 25px;
            margin: 7px auto;
            text-indent: -9999px;
            border-top: 1px solid $borderColor;
        }
        }
      }
    }
  }
}

.dashboard-body-section{
  padding: 15px 0 15px 15px;
  width: calc(100% - 70px);
  height: 100%;
}

.add-upload-charge{
  border: none;
  background: none;
  color: $shadowColor;
  font-weight: bold;
  span{
    border: 2px solid $themeColor;
    color: $themeColor;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: block;
    float: left;
    line-height: 14px;
    text-align: center;
    margin-right: 10px;
    margin-top: 3px;
  }
  &:focus{
    outline: none;
  }
}

.white-bg{
  background-color: $whiteColor !important;
}

.upload-search{
  padding: 10px;
  border-bottom: 1px solid $borderColor;
  position: relative;
}

.searh-in-table{
  border-bottom: 1px solid $borderColor;
  display: table;
  float: right;
  color: $shadowColor;
  input{
    padding: 5px 10px;
    border: none;
    &:focus{
      outline: none;
    }
  }
}

.overflow-auto{
  overflow: auto;
}

.m-0{
  margin: 0px;
}

.main-data-table{
  border-collapse: collapse;
  width: 100%;
  tr{
    border-bottom: 1px solid $borderColor;
    th{
      color: $whiteColor;
      font-size: 13px;
      padding: 10px;
      background-color: $tableHeader;
    }
    td{
      color: $fontColor;
      font-size: 14px;
      padding: 9px;
      .data-info-main{
        display: block;
        padding: 0px;
        margin-left: 20px;
        .data-status{
          border-radius: 50%;
          height: 12px;
          width: 12px;
          font-weight: normal;
          color: $whiteColor;
          font-size: 8px;
          padding: 2px;
          margin-right: 5px;
          text-align: center;
        }
      }
    }
  }
  tbody{
    tr{
      position: relative;
    }
  }
}

.data-info{
  border-radius: 50%;
  height: 12px;
  width: 12px;
  font-weight: normal;
  color: $whiteColor;
  font-size: 8px !important;
  padding: 2px;
  margin-right: 5px;
  text-align: center;
  vertical-align: middle !important;
}

.blue-clr{
  color: $themeColor !important;
}

.falkon-data-table{
  .rt-tr-group{
    text-align: center;
    cursor: pointer;
  }
  .data-info-main{
    display: inline-block;
    padding: 8px;
    .data-info{
      border-radius: 50%;
      height: 12px;
      width: 12px;
      font-weight: normal;
      color: $whiteColor;
      font-size: 8px;
      padding: 2px;
      margin-right: 5px;
      text-align: center;
    }
  }

  .action-div{
    overflow: unset !important;
    .dropdown-toggle:after{
      display: none !important;
    }
  }
}

.padding-0{
  padding: 0px !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $borderColor; 
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.green-bg{
  background-color: $green;
}

.red-bg{
  background-color: $red;
}

.font-red{
  color: $red !important;
}

.search-filter-bg{
  background-color: $extraLightBorderColor !important;
}

.font-white{
  color: $whiteColor !important;
}

.blue-bg{
  background-color: $themeColor !important;
}

.dashboard-header{
  background-color: $whiteColor;
  height: 70px;
  padding: 3px 15px;
  justify-content: space-between;
  border-bottom: 2px solid $themeColor;
  padding-left: 50px;

  .logo-img{
    height: 60px;
    margin-right: 10px;
    float: left;
  }

  .header-text{
    line-height: 60px;
    margin: 0;
    display: table;
    color: $leftMenuBg;
    white-space: nowrap;
  }

  .dashboard-user{
    text-align: right;
    .user-img{
      height: 40px;
      margin-right: 10px;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid $themeColor;
    }
    p{
      display: inline-block;
    }
  }
}

.patient-info{
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 20px 0px $shadowColor;
  background-color: $whiteColor;
  max-width: 900px;
  height: 100%;
  max-height: 280px;
  overflow: auto;
  border-radius: 5px;
  z-index: 1;
  .patient-submit{
    border: none;
    border-radius: 5px;
    background-color: $themeColor;
    color: $whiteColor;
    font-size: 14px;
    margin-left: 10px;
    padding: 4px 8px;
  }
  .patient-table-div{
    max-height: calc(100% - 65px);
  }
}

.minus-icon{
  &::before{
    line-height: 38px;
  }
}

.close:focus{
  outline: none;
}

.falkon-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $extraLightBorderColor;
    border: 1px solid $themeColor;
    border-radius: 3px;
  }

  &:hover input ~ .checkmark {
    background-color: $borderColor;
  }

  input:checked ~ .checkmark {
    background-color: $themeColor;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid $whiteColor;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

}

.area-100{
  height: 100%;
  width: 100%
}

.upload-charge-popup{
  background-color: $whiteColor;
  .upload-header{
    padding: 10px 15px;
    background-color: $themeColor;
    color: $whiteColor;
    font-size: 18px;
    white-space: nowrap;
  }
  .upload-body{
    padding: 15px 0;
    label{
      color: $fontColor;
    }
    .upload-file{
      padding: 10px 15px;
      cursor: pointer;
      background-color: $themeColor;
      color: $whiteColor;
      border-radius: 5px;
    }
    .upload-file-name{
        border: none;
        border-bottom: 1px solid $borderColor;
        width: 100%;
        &:focus{
          outline: none;
        }
    }
    .upload-charge-btn{
      text-align: right;
      input{
        display: inline-block;
        border: none;
        outline: none;
        padding: 0;
        background: none;
        margin: 0 10px;
        color: $fontColor;
        &:focus, &:hover{
          color: $themeColor;
        }
      }
    }
  }
}

.font-12px{
  font-size: 12px;
}

.upload-large{
  width: 400px;
  -webkit-animation: rightMenuLarge 0.3s;
  animation: rightMenuLarge 0.3s;
}

.upload-small{
  width: 0px;
  -webkit-animation: rightMenuSmall 0.3s;
  animation: rightMenuSmall 0.3s;
}

@keyframes rightMenuLarge {
  0% {
    width: 0px;
  }

  100% {
    width: 400px;
  }
}

@-webkit-keyframes rightMenuLarge {
  0% {
    width: 0px;
  }

  100% {
    width: 400px;
  }
}

@keyframes rightMenuSmall {
  0% {
    width: 400px;
  }

  100% {
    width: 0px;
  }
}

@-webkit-keyframes rightMenuSmall {
  0% {
    width: 400px;
  }

  100% {
    width: 0px;
  }
}

.overflow-x-hidden{
  overflow-x: hidden;
}

.dropdown-menu{
  left: -75px !important;
  top: 5px !important;
  padding: 0px !important;
  max-height: 430px;
  overflow: auto;
  li{
    border-bottom: 1px solid $lightBorderColor;
    &:hover{
      background-color: $extraLightBorderColor;
    }
  }
}

.trans-btn{
  border: none;
  background: none;
  padding: 0;
  position: relative;
  &:after{
    content: none !important;
  }
  &:focus{
    outline: none;
  }
}

.blue-bg-white-text{
  background-color: $themeColor;
  color: $whiteColor;
  .close{
    color: $whiteColor !important;
    opacity: 1 !important;
  }
}
.loader-main{
  position: fixed;
  background-color: rgba(12, 142, 250, 0.2);
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  .falkon-data-loader,
  .falkon-data-loader:before,
  .falkon-data-loader:after {
    background: $themeColor;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .falkon-data-loader {
    color: $themeColor;
    text-indent: -9999em;
    position: absolute;
    font-size: 11px;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .falkon-data-loader:before,
  .falkon-data-loader:after {
    position: absolute;
    top: 0;
    content: '';
  }
  .falkon-data-loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .falkon-data-loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
}

.inpage-loader{
  position: absolute;
  background-color: rgba(12, 142, 250, 0.2);
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  .falkon-data-loader,
  .falkon-data-loader:before,
  .falkon-data-loader:after {
    background: $themeColor;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .falkon-data-loader {
    color: $themeColor;
    text-indent: -9999em;
    position: absolute;
    font-size: 11px;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .falkon-data-loader:before,
  .falkon-data-loader:after {
    position: absolute;
    top: 0;
    content: '';
  }
  .falkon-data-loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .falkon-data-loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
}

.main-table-data{
  height: calc(100% - 65px);
  overflow: auto;
}

.patient-info-status{
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-left: 10px;
}

.refresh-btn{
  background-color: $themeColor !important;
  color: $whiteColor !important;
  float: right !important;
  margin-left: 15px;
}

.label-count-list{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  height: 100%;
  li{
    min-width: 110px;
    border-right: 1px solid $borderColor;
    margin-left: 30px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      border: none;
    }
    label{
      margin: 0;
      font-size: 12px;
      line-height: 1;
    }
    h5{
      margin: 0;
      color: $themeColor;
      font-weight: bold;
      line-height: 1;
    }
  }
}

.data-table-filters{
  height: 100%;
  display: flex;
  max-height: 30px;
  justify-content: flex-end;
  .search-div{
    max-width: 200px;
    border: 1px solid $borderColor;
    border-radius: 4px;
    display: flex;
    background-color: $whiteColor;
    input{
      border: none;
      height: 100%;
      padding: 0 5px;
      max-width: 160px;
      border-radius: 5px;
      &:focus{
        outline: none;
      }
    }
    .fa-search{
      color: $borderColor;
      margin: 6px;
    }
  }
  .filters{
    margin-left: 5px;
    max-width: 130px;
    width: 130px !important;
    border: 1px solid $borderColor;
    display: flex;
    background-color: $leftMenuBg;
    border-radius: 4px;
    color: $whiteColor;
    max-height: 100%;
    .react-datepicker-wrapper, .react-datepicker__input-container, input{
      width: 100%;
    }
    input, select{
      // border: 1px solid $borderColor;
      border: none;
      border-radius: 4px;
      padding: 0 5px;
      height: 30px;
      background-color: transparent;
      color: $whiteColor;
      font-size: 13px;
      width: 100%;
      &:focus{
        outline: none;
      }
    }
    i.fa, label{
      margin-left: 5px !important;
      color: $whiteColor;
      margin-top: 7px !important;
    }
  }
}

.archive-btn{
  border: none;
  background-color: $extraLightBorderColor;
  box-shadow: $boxShadowStandered;
  padding: 0 8px;
  border-radius: 5px;
  min-width: 50px;
  font-size: 12px;
  &:hover, &:focus{
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
    outline: none;
  }
}

.contact-popup{
  max-width: 400px !important;
}

.name-dob-popup{
  max-width: 600px !important;
}

.plain-btn{
  margin: 0;
  padding: 0;
  line-height: 1;
  background: none;
  border: none;
  &:focus{
    outline: none;
  }
}

.inline-right-btn{
  text-align: right;
  display: inline-block;
}

.cancel-btn{
  color: $fontColor;
}

.save-btn{
  background-color: $leftMenuBg !important;
  padding: 5px 20px;
  margin-left: 10px;
  color: $whiteColor !important;
  border-radius: 4px;
}

.resend-btn{
  padding: 0 8px !important;
}

.dashboard-left-nav-2{
  .new-fax-btn-text, .menu-labels{
    white-space: nowrap;
    line-height: 1;
    opacity: 1;
  }
  min-width: 55px !important;
  max-width: 230px;
  height: calc(100%);
  background-color: $whiteColor;
  overflow: auto;
  -webkit-transition: width .5s ease;
  -o-transition: width .5s ease;
  transition: width .5s ease;
  border-right: 5px solid $whiteColor;
  .all-doc-count{
    min-width: 160px;
  }
  .new-fax-btn{
    margin: auto;
    display: block;
    border-radius: 25px;
    background-color: $whiteColor;
    border: none;
    width: 100%;
    color: $leftMenuBg;
    padding: 5px;
    max-width: 155px;
    font-weight: bold;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
    span.plus{
      font-size: 25px;
      line-height: 1;
      vertical-align: top;
    }
    &:hover, &:focus{
      box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
      outline: none;
    }
  }
  .left-nav-items-2{
    padding: 0px;
    list-style-type: none;
    padding-top: 5px;
    border-top: 1px solid $borderColor;
    // border-bottom: 1px solid $borderColor;
    padding-right: 10px;
    height: calc(100% - 75px);
    overflow: auto;
    margin-bottom: 0;
    li{
      padding: 5px 15px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      font-size: 13px;
      color: $themeColor;
      max-height: 31px;
      i{
        width: 18px;
      }
      &:hover{
        color: $whiteColor;
        background-color: $themeColor;
      }
    }
    li.active{
      color: $whiteColor;
      background-color: $themeColor;
    }
    .inbox-menu-icon{
      margin-right: 15px;
    }
  }
}

.plus-icon{
  float: left;
  height: 21px;
  width: 21px;
}

.create-manage-labels{
  margin: 0;
  padding: 0;
  list-style-type: none;
  li{
    padding: 2px 15px;
    button{
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
}

.main-menu-large{
  .dashboard-left-nav-2{
    width: 230px;
  }
}

.main-menu-small{
  .dashboard-left-nav-2{
    width: 65px;
  }
}

.font-14px{
  font-size: 14px;
}

.new-fax-main-div{
  padding: 15px;
}

.create-label-btn{
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  margin-left: 15px;
  color: $themeColor;
  span.plus{
    line-height: 1;
    vertical-align: top;
  }
}

.dashboard-body-section-2{
  // padding: 15px 0 0px 15px;
  width: 100%;
  height: 100%;
}

.ptb-15px{
  padding: 15px 0 !important;
}

.ptb-10px{
  padding: 10px 0 !important;
}

.header-menu-btn{
  line-height: 70px !important;
  color: $leftMenuBg;
  font-size: 20px !important;
  margin-right: 15px;
  position: fixed;
  top: 0;
  left: 15px;
}

@media(max-width: 600px){
  .dashboard-header{
    flex-wrap: nowrap !important;
    .header-text{
      font-size: 20px;
    }
  }
}

@media(max-width: 450px){
  .dashboard-header{
    flex-wrap: nowrap !important;
    .header-text{
      display: none;
    }
  }
}

.inbox-action{
  // border: 1px solid $borderColor;
  border: 1px solid $borderColor;
  border-radius: 4px;
  padding: 0 5px;
  height: 30px;
  background-color: $extraLightBorderColor;
  color: $fontColor;
  font-size: 12px;
  width: 100%;
  &:focus{
    outline: none;
  }
}

.font-theme{
  color: $themeColor;
}

.add-edit-icon{
  color: $themeColor;
  font-size: 10px !important;
  vertical-align: middle;
  cursor: pointer;
}

.drop-file-btn{
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: $themeColor;
  &:focus{
    outline: none;
  }
}

.mw-60px{
  min-width: 60px;
}

.inbox-edit-add-name{
    display: flex;
    i{
      line-height: 2;
    }
}

.my-dropzone{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:focus{
    outline: none;
  }
}

.mb-10px{
  margin-bottom: 10px;
}

.dropzone-main-div{
  border: 1px $borderColor dashed;
  border-radius: 4px;
  height: 214px;
  section{
    height: 100%;
    padding-top: 12px;
  }
  .dropzone{
    height: 100%;
  }
}

.file-send-btn{
  background-color: $themeColor !important;
  color: $whiteColor !important;
  width: 100%;
}

.file-textarea{
  resize: none;
  margin-top: 23px;
  height: 177px !important;
}

.multiselect {
  position: relative;

  .selectBox {
    position: relative;

    select {
      width: 100%;
    }
  }

  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  #checkboxes {
    border: 1px $lightBorderColor solid;
    position: absolute;
    background-color: $whiteColor;
    left: auto;
    right: 0;
    min-width: 535px;
    top: 100%;
    z-index: 1000;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
    box-shadow: 5px 5px 10px 5px $borderColor;
    .checkboxes-header{
      padding: 5px 0;
      border-bottom: 1px solid $borderColor;
      display: table;
      width: 100%;
      .reset-filter-btn{
        border: none;
        padding: 0;
        margin: 0;
        font-weight: bold;
        border-bottom: 1px solid $blackColor;
        border-radius: 0;
        font-size: 12px;
        float: right;
        display: block;
        line-height: 1;
        &:focus{
          outline: none !important;
          border: none;
          box-shadow: none;
        }
      }
    }

    label {
      display: block;
      input[type='checkbox'] {
        vertical-align: middle;
      }
    }
  }
}

.react-date-picker {
  width: 100%;

  .react-date-picker__wrapper {
    width: 100%;
    border-radius: 4px;
    border: 1px solid $borderColor;
    background-color: $whiteColor;

    .react-date-picker__inputGroup {
      min-height: 34px;
      white-space: nowrap;
    }

    input,
    button {
      outline: none;
    }
  }
}

.collapse-table{
  tr:nth-child(even){
    border: none;
    td{
      padding: 0;
    }
  }
}

.pdf-collapse{
  overflow: auto;
  transition: height 0.5s;
}

.pdf-collapse.small{
  height: 0px;
}

.pdf-collapse.large{
  height: 400px;
}

.side-popup {
  position: fixed;
  bottom: 70px;
  left: 30px;
  background-color: $themeColor;
  border: 4px double $whiteColor;
  color: $whiteColor;
  text-transform: capitalize;
  padding: 10px;
  min-width: 350px;
  border-radius: 5px;

  h5 {
    margin: 0;
  }

  .close {
    opacity: 1;
    color: $whiteColor;
    position: absolute;
    right: 5px;
    top: 0px;
    font-weight: normal;
  }
}

.sent-box-btn{
  line-height: 1 !important;
  font-size: 12px;
  padding: 0.15rem 0.75rem !important;
  text-transform: capitalize;
}

.sent-box-btn:not(.btn){
  text-align: center;
  border-radius: 5px;
  max-width: 75px;
  display: inline-block;
}

.sent-box-btn.btn-success:not(.btn){
  &:hover{
    background-color: $green !important;
  }
}

.sent-box-btn.btn-danger:not(.btn){
  &:hover{
    background-color: $red !important;
  }
}

.action-div{
  overflow: unset !important;
  .dropdown-toggle:after{
    display: none !important;
  }
}

.hover-row:hover{
  box-shadow: 0 0px 10px 0px $borderColor;
  cursor: pointer;
}

.full-width{
  width: 100%;
}

.detail-heads{
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $extraLightBorderColor;
  li{
    display: inline-block;
    padding: 0 10px;
    border-right: 1px solid $extraLightBorderColor;
    text-transform: capitalize;
    &:last-child{
      border: none;
    }
  }
}

.back-btn{
  background-color: $borderColor !important;
  border-radius: 50% !important;
  padding: 0.375rem !important;
  line-height: 1 !important;
  color: $whiteColor !important;
  &:hover{
    border-radius: 0.25rem !important;
    color: $whiteColor !important;
  }
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

.detail-action{
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: right;
  li{
    display: inline-block;
    padding: 0 10px;
    text-transform: capitalize;
    line-height: 34px;
    button.back-btn{
      border-radius: 0.5rem !important;
      text-transform: capitalize;
      text-align: center;
      &:hover{
        box-shadow: 0 0 5px 5px $extraLightBorderColor !important;
      }
    }
    .dropdown-menu{
      transform: none !important;
      top: 25px !important;
      right: 0px !important;
      left: auto !important;
      li{
        white-space: nowrap;
        border-bottom: 1px solid $borderColor;
      }
    }
  }
}

.dropdown-toggle{
  &:after{
    border: none !important;
    content: none !important;
  }
}

.initial-letter{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: $extraLightBorderColor;
  border: 1px solid $borderColor;
  margin-right: 20px;
  text-transform: uppercase;
  line-height: 38px;
  text-align: center;
  font-size: 20px;
}

.d-flex{
  display: flex;
}

.lh-1{
  line-height: 1;
}

.fax-to{
  font-size: 12px;
  color: rgba(0,0,0,0.5);
}

.fax-from{
  line-height: 1;
  margin-top: 5px;
}

.detail-recieve-date{
  position: absolute;
  top: 10px;
  right: 10px;
}

.ml-15px{
  margin-left: 15px !important;
}

.mr-15px{
  margin-right: 15px !important;
}

.preview-popup{
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 70px);
  background-color: rgba(0,0,0,0.5);
  z-index: 20;
  .close{
    font-size: 40px;
    margin-right: 20px;
    margin-top: 10px;
    color: $whiteColor;
    opacity: 1;
  }
  iframe{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.sent-msg-card{
  .header{
    padding: 5px 10px !important;
    color: $whiteColor;
  }
  .body{
    padding: 5px 10px !important;
  }

}

#my-pdf{
  max-width: 100%;
  width: 100% !important;
  height: 100% !important;
  overflow: auto;
  canvas{
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.text-primary{
  color: $blue;
}

.text-danger{
  color: $red;
}

.text-success{
  color: $green;
}

.assigned-label{
  background-color: $borderColor;
  color: $blackColor;
  padding: 0px 6px;
  padding-right: 20px;
  margin: 2px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  position: relative;
  white-space: nowrap;
  .close{
    font-size: 17px;
    line-height: 1;
    color: $blackColor;
    opacity: 1;
    position: absolute;
    right: 6px;
  }
}

.assigned-label-main{
  max-height: 100px;
  overflow: auto;
}

.react-date-picker__calendar{
  top: 100% !important;
}

.react-calendar{
  .react-calendar__navigation{
    margin: 0;
    height: 30px;
    background-color: $themeColor;
    button{
      color: $whiteColor;
    }
  }
  .react-calendar__tile{
    padding: 0.25em 0.5em;
  }
}

.pdf-btns{
  position: absolute;
  top: 10px;
  line-height: 24px !important;
  cursor: pointer;
  z-index: 10;
}

.pdf-btns.download{
  right: 10px;
}

.pdf-btns.rotate{
  right: 40px;
  color: $blue;
  &:hover{
    color: $themeColor;
  }
}

.detail-assign-dd{
  li{
    width: 100%;
    button{
      text-align: left;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: $blue;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $themeColor;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $borderColor;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: $whiteColor;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}

.label-assign-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  li{
    display: inline-block;
    min-width: 185px;
  }
}

.add-admin-user{
  display: table;
  border-bottom: 1px solid $borderColor;
  width: 100%;
  padding: 10px;
}

.multiselect {
  position: relative;

  .selectBox {
    position: relative;

    select {
      width: 100%;
    }
  }

  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  #checkboxes {
    border: 1px $extraLightBorderColor solid;
    position: absolute;
    background-color: $whiteColor;
    right: 0;
    top: 100%;
    z-index: 1000;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;

    label {
      display: block;

      input[type='checkbox'] {
        vertical-align: text-top;
      }
    }
  }
}

.edit-user-info-popup{
  max-width: 600px;
  display: table;
}

.send-new-msg-popup{
  height: 100%;
  width: 45%;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: $whiteColor;
  border-left: 1px solid $borderColor;
  // position: absolute;
  // top: 75px;
  // height: calc(100% - 75px);
  // bottom: 0;
  // right: 0;
  // width: 400px;
  // box-shadow: -3px 0px 10px 3px $borderColor;
  .send-msg-popup-header{
    padding: 15px;
    border-bottom: 1px solid $borderColor;
    background-color: $tableHeader;
    color: $whiteColor;
    .close{
      color: $whiteColor;
      opacity: 1;
    }
  }
  .send-body{
    height: 100%;
    overflow: auto;
  }
}

.popup-header{
  padding: 15px;
  border-bottom: 1px solid $borderColor;
  background-color: $blue;
  color: $whiteColor;
  .close{
    color: $whiteColor;
    opacity: 1;
  }
}

.mt-15px{
  margin-top: 15px;
}

.lh-39px{
  line-height: 39px;
}

.center-block{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.profile-main-page{
  padding: 15px 0;
  overflow: auto;
  background-color: $whiteColor;
  height: 100%;
  max-width: 500px;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 5px $borderColor;
}

.forget-password-popup{
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 20px 0px $shadowColor;
  background-color: $whiteColor;
  max-width: 400px;
  display: table;
  overflow: auto;
  border-radius: 5px;
  z-index: 20;
}

.upload-new-file{
  font-size: 15px;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 30px;
  color: $leftMenuBg;
  span{
    font-size: 14px;
    margin-left: 10px;
    vertical-align: middle;
    max-width: 120px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.patient-info-send{
  background-color: $lightBorderColor !important;
  cursor: pointer;
}

.msg-to-suggession-box{
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 4px;
  margin: 0px;
  list-style-type: none;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
  background-color: $whiteColor;
  padding-left: 0;
  box-shadow: $boxShadowStandered;
  li{
    border: 1px solid $borderColor;
    border-bottom: none;
    padding: 5px 10px;
    cursor: pointer;
    &:last-child{
      border-bottom: 1px solid $borderColor;
    }
    .head{
      font-size: 14px;
      .phone{
        color: $fontColor;
      }
    }
    .sub-head{
      font-size: 13px;
      .phone{
        color: $fontColor;
      }
    }
  }
}

.new-msg-patient-collapse{
  transition: height 0.2s;
  overflow: hidden;
}

.new-msg-patient-collapse.open{
  height: 470px;
}

.new-msg-patient-collapse.close{
  height: 0px;
}

.btn.btn-default{
  border: 1px solid $borderColor;
  background-color: $whiteColor;
  &:hover{
    background-color: $extraLightBorderColor;
    border: 1px solid $borderColor;
  }
  &:focus{
   outline: none;
   box-shadow: none;
   border: 1px solid $borderColor;
  }
}

.confirmation-popup{
  position: absolute;
  display: table;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: $whiteColor;
  min-width: 300px;
  box-shadow: 3px 3px 15px 5px $borderColor;
  border-radius: 4px;
  .popup-header, .popup-body, .popup-footer{
    width: 100%;
    display: table;
    padding: 10px;
    border-bottom: 1px solid $lightBorderColor;
  }
  .popup-footer{
    text-align: right;
  }
}

.btn.dropdown-toggle{
  &:focus{
    outline: none;
    box-shadow: none;
  }
}

.ml-20px{
  margin-left: 20px !important;
}

.ml-10px{
  margin-left: 10px !important;
}

.gmail-hover-btn{
  border: none;
  background: none;
  display: inline-block;
  height: 20px;
  margin: 0;
  padding: 0;
  -webkit-transition: opacity .15s cubic-bezier(0.4,0.0,0.2,1);
  transition: opacity .15s cubic-bezier(0.4,0.0,0.2,1);
  width: 20px;
  color: $leftMenuBg;
  position: relative;
  cursor: pointer;
  text-align: center;
  &:focus{
    outline: none;
  }
  &:after{
    content: '';
    height: 200%;
    position: absolute;
    top: -60%;
    left: -50%;
    width: 200%;
    border-radius: 50%;
    background-color: transparent;
  }
  &:hover::after{
    background-color: rgba(0, 0, 0, 0.1)
  }
}

.header-menu{
  z-index: 10001 !important;
  li{
    padding: 5px 10px;
    white-space: nowrap;
    i{
      min-width: 17.5px;
      &::before{
        text-align: center;
        display: block;
      }
    }
  }
}

.no-padding{
  padding: 0px !important;
}

.nowrap{
  white-space: nowrap;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.gray-bg{
  background-color: $loginBg;
}

.report-body{
  height: 100%;
  overflow: auto;
  width: calc(100% - 230px);
  margin-left: 5px;
  background-color: $whiteColor;
  .main-data-table{
    th{
      text-transform: capitalize;
    }
  }
}

.assign-labels{
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $whiteColor;
  padding: 0 10px;
  border: 1px solid $borderColor;
  border-radius: 4px;
  z-index: 10;
  max-height: 200px;
  overflow: auto;
}

.sticky{
  position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
  position: sticky !important;
  background-color: $whiteColor;
  z-index: 1;
  white-space: nowrap;
}

.opacity-5{
  opacity: 0.5;
}

.send-selected-files{
  list-style-type: none;
  margin: 0;
  padding: 0;
  li{
    padding: 3px 10px;
    border: 1px solid $borderColor;
    border-radius: 4px;
    background-color: $extraLightBorderColor;
    box-shadow: $boxShadowStandered;
    margin-bottom: 10px;
  }
}

.inbox-table-container{
  width: 100% !important;
  height: 100%;
  // overflow: hidden;
}

.inbox-table-container-side{
  width: 55%;
  float: left;
  overflow: hidden;
  height: 100%;
  padding-right: 5px;
  // resize: horizontal;
  min-width: 500px;
}

.msg-detail-container{
  width: 45%;
  float: right;
  height: calc(100%);
  padding-left: 5px;
  position: relative;
  min-width: 400px;
  .mail-svg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);
    height: 150px;
    width: 150px;
    p{
      white-space: nowrap;
      color: $fontColor;
      padding-top: 10px;
    }
  }
}

.msg-detail-head{
  white-space: nowrap;
  color: $fontColor;
  font-size: 12px;
}

.msg-detail-value{
  font-size: 13px;
}

.detail-list{
  box-shadow: $boxShadowStandered;
  li{
    padding: 0 10px;
    border-bottom: none !important;
  }
}

.conversation-list{
  box-shadow: $boxShadowStandered;
  li{
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
    &:last-child{
      border-bottom: none !important;
    }
    .item{
      line-height: 1.3;
    }
  }
}

.attatchment-name-list{
  margin: 0;
  padding: 0;
  list-style-type: none;
  li{
    button{
      box-shadow: $boxShadowStandered;
      border: none;
      background: none;
      margin: 10px;
      padding: 5px 10px;
      &:focus{
        outline: none;
      }
      &:hover, &:focus{
        background-color: $extraLightBorderColor;
      }
    }
  }
}

.detail-msg{
  text-align: justify;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.send-pin-container{
  .pin-page-logo{
    display: block;
    margin: 0 auto;
    height: 100px;
    width: auto;
  }
  .pin-page-text{
    text-align: center;
    color: $themeColor;
  }
  .pin-box-container{
    max-width: 500px;
    padding: 0.75rem;
    border: 1px solid $extraLightBorderColor;
    border-radius: 0.25rem;
    box-shadow: $boxShadowStandered;
  }
}

.pin-btn-group{
  text-align: center;
  button, .btn{
    margin-right: 15px;
    &:last-child{
      margin-right: 0;
    }
  }
}

.header-initials{
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: $leftMenuBg;
  color: $whiteColor;
  font-weight: bold;
  vertical-align: middle;
}

.read-indicator{
  height: 5px;
  width: 5px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  background-color: $red;
  margin-top: 8px;
  margin-right: 10px;
}

.corporate-logo{
  height: 60px;
  background-color: $whiteColor;
  border-radius: 10px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  img{
    height: 100%;
  }
}

.blur-bg{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(12, 142, 250, 0.2);
  z-index: 1;
}







.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  color: $leftMenuBg
}
.checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: $whiteColor;
  border-radius: 5px;
  border: 2px solid $leftMenuBg;
  display: inline-block;
  vertical-align: text-bottom;
  width: 18px; 
  height: 18px;
  padding: 2px; 
  margin-right: 5px;
}
.checkbox-custom:checked + .checkbox-custom-label:after {
  content: "";
  padding: 2px;
  position: absolute;
  width: 7px;
  height: 10px;
  border: solid $leftMenuBg;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  top: 6px; 
  left: 6px;
}

.go-analytics{
  line-height: 39px !important;
  color: $themeColor;
  cursor: pointer;
}

.js-plotly-plot{
  margin: 0 auto;
}

.donut-icon{
  max-height: 25px;
  cursor: pointer;
  vertical-align: text-top;
}

.archive{
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  &::before{
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
    z-index: -1;
    bottom: -10px;
    left: -10px;
    right: -10px;
    top: -10px;
    background: none;
    border-radius: 50%;
    box-sizing: border-box;
    transform: scale(0);
    transition-property: transform,opacity;
  }
}

.label-divider{
  display: flex;
  height: 21px;
  padding: 0 !important;
  .divider-line{
    border-bottom: 1px solid $borderColor;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  i{
    margin-left: 5px;
    margin-right: 5px;
    color: $themeColor;
    cursor: pointer;
    &::before{
      vertical-align: -webkit-baseline-middle;
    }
  }
  &:hover{
    background-color: transparent !important;
  }
}

.action-btn{
  border: 1px solid $leftMenuBg;
  background: none;
  padding: 0px 10px;
  margin: 0;
  border-radius: 4px;
  border-left: 6px solid $leftMenuBg;
  margin-left: 10px;
  // border-bottom: 3px solid $themeColor;

  &:focus{
    outline: none;
    box-shadow: none;
  }
}

.row-action-btn{
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: absolute;
  right: 50px;
  padding-top: 5px;
  top: 5px;
  bottom: 5px;
  min-width: 80px;
  li{
    text-align: center;
  }
  &:focus{
    background-color: $lightBorderColor !important;
  }
}

.system-labels{
  font-size: 15px !important;
}

.assigned-label-new{
  height: 25px;
  border: 1px solid $borderColor;
  border-radius: 10px;
  padding: 0 5px;
  text-align: center;
  position: relative;
  color: $whiteColor;
  background-color: $green;
  margin: 0 5px;
  padding-right: 20px;
  .close{
    font-size: 13px;
    line-height: 1;
    color: $green;
    opacity: 1;
    right: 0;
    position: absolute;
    right: 3px;
    top: 5px;
    background-color: $whiteColor;
    width: 13px;
    border-radius: 50%;
  }
}
.assigned-label-manual{
  height: 25px;
  border: 1px solid $borderColor;
  border-radius: 10px;
  padding: 0 5px;
  text-align: center;
  position: relative;
  color: $whiteColor;
  background-color: $green;
  margin: 0 5px;
  padding-right: 20px;
  .close{
    font-size: 13px;
    line-height: 1;
    color: $green;
    opacity: 1;
    right: 0;
    position: absolute;
    right: 3px;
    top: 5px;
    background-color: $whiteColor;
    width: 13px;
    border-radius: 50%;
  }
}
.assigned-label-auto{
  height: 25px;
  border: 1px solid $borderColor;
  border-radius: 10px;
  padding: 0 5px;
  text-align: center;
  position: relative;
  color: $whiteColor;
  background-color: $blue;
  margin: 0 5px;
  padding-right: 20px;
  .close{
    font-size: 13px;
    line-height: 1;
    color: $blue;
    opacity: 1;
    right: 0;
    position: absolute;
    right: 3px;
    top: 5px;
    background-color: $whiteColor;
    width: 13px;
    border-radius: 50%;
  }
}

.left-nav-bg{
  background-color: $leftMenuBg !important;
}

.left-nav-color{
  color: $leftMenuBg !important;
}

@media all and (min-width: 992px) {
  
}

.check-hover{
  .dropdown-menu{ 
    display: none; 
  }
  &:hover{
    .dropdown-menu{ 
      display: block; 
    }
  }
}

.cursor-move{
  cursor: move;
}

.add-rule-btn{
  float: right;
  cursor: pointer;
  opacity: 0.5;
  padding-top: 5px;
}

.label-rule-container {
  padding: 0;
  list-style-type: none;
  width: 100%;
  li{
    border: 1px solid $borderColor;
    border-radius: 4px;
    margin-bottom: 10px;
    .rule-head{
      background-color: $leftMenuBg;
      color: $whiteColor;
      padding-top: 5px;
      padding-bottom: 5px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      cursor: pointer;
      .close{
        opacity: 1;
        color: $whiteColor;
      }
      label{
        margin: 0;
        font-size: 13px;
        width: calc(100% - 15px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}

.rule-collapse{
  overflow: hidden;
  height: 36px;
  .rule-head{
    label{
      line-height: 25px;
    }
  }
}

.rule-uncollapse{
  overflow: auto;
  height: auto;
  .rule-head{
    label{
      line-height: 10px;
    }
  }
}

.card-main-div{
  // background-color: #82bfe3;
  padding: 25px 0;
  // margin-bottom: 30px;
}

.header-card{
  box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
  min-height: 100px; 
  margin: 20px auto;
  max-width: 300px;
  border-radius: 12px;
  text-align: left;
  padding: 20px;
  position: relative;
  font-weight: bold;
  background-color: $whiteColor;
  label{
    margin: 0;
    font-size: 13px;
  }
  p{
    font-size: 25px;
    margin: 0;
  }

  .card-icon{
    position: absolute;
    top: -20px;
    right: 50px;
    height: 60px;
    width: 60px;
    border-radius: 5px;
    border: 1px solid $borderColor;
    text-align: center;
    line-height: 55px;
    color: $whiteColor !important;
    font-size: 25px;
  }
  .all{
    color: $red;
    border-bottom: 3px solid $red;
    .card-icon{
      background-color: $red;
    }
  }
  .archived{
    color: $blue;
    border-bottom: 3px solid $blue;
    .card-icon{
      background-color: $blue;
    }
  }
  .inprogress{
    color: $green;
    border-bottom: 3px solid $green;
    .card-icon{
      background-color: $green;
    }
  }
}

.graph-bg{
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
  background-color: $whiteColor;
  position: relative;
  border: 0;
  transition: transform .3s cubic-bezier(.34,2,.6,1);
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: initial;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}